.breadcrumb {
  margin-left: 20px;
  margin-top: 10px;
  .breadcrumb-item {
    font-size: 16px;
  }
}



.sectionContainer {
  margin-left: 20px;

  
  h2 {
    font-size: 20px;
    color: grey;
  }

  .infotable {
    tr {
    }
    td {
      padding-right: 20px;
    }
  }

  .topContainer {
    display: flex;
    flex-direction: row;
    // border: 1px solid red;
    margin-bottom: 20px;
    justify-content: center;
    gap: 20px;

    width: 800px;

    .left {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      width: 400px;
    }

    .right {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      width: 400px;
    }
  }

  .bottomContainer {
    width: 800px;

    // width: "80%";
    // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  }
}
