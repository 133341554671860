.dashboard {
    display: flex;
    width: 100%;

    .spotprice {
        width: 700px;

        // border: 1px solid red;
        // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        // padding: 20px;
        margin: 10px 20px;
    }
    
}

