.meterInfo {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    color: gray;
    overflow: scroll;

    .header {
        display: flex;
        padding: 10px;
        .title2 {
            font-size: 16px;
            color: lightgray;
            white-space: nowrap;
        }
    }

    .content {
        display: flex;
        padding-bottom: 20px;
        
        
        .iconDiv {
            // display: flex;
            margin-right: 10px;
            .itemImg {
                flex: 1;
                min-width: 100px;
                height: 100px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .details {
            display: flex;  
            padding-right: 15px;
            font-size: 14px;

            .detailLabels {
                font-weight: bold;
                color: gray;
                white-space: nowrap;
                padding-right: 10px;
            }
            .detailValues {
                white-space: nowrap;
                min-width: 40px;
            }

            .itemValue, .itemKey {
                padding-bottom: 6px;

            }

        }

    }

}