.login {
    width: 100vw;
    height: 100vh;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    justify-content: center;

    .wrapper {
        display: flex;
        flex-direction: column;
        padding: 20px 10px;
        background-color: white;

        align-items: center;
        justify-content: center;
        width: 350px;

        border: 1px solid rgb(230, 227, 277);
        border-radius: 10px;

        
        .title {
            display: flex;
            text-align: center;
            font-size: 32px;
            color: #3070aa;
            margin-bottom: 20px;
        }

        .errorMsg {
            display: flex;
            justify-content: center;
            color: red;
            margin-bottom: 10px;
        }
    
        form {
            width: 90%;
            // display: flex; 
            // flex-wrap: wrap;
            gap: 30px;
            justify-content: space-around;

            .formInput {
                width: 100%;
                padding-bottom: 10px;

                label {
                    display: flex;
                    align-items: center;
                    gap: 100px;
                    margin-bottom: 5px;
                    .icon {
                        cursor: pointer;
                    }
                }

                input {
                    width: 100%;
                    box-sizing: border-box;
                    height: 40px;
                    border-radius: 10px;
                    border: 1px solid grey;
                    font-size: 18px;
                    padding-left: 10px;
                }

                input:focus {
                    // outline: none;
                }
            }

            .loginButton {
                width: 100%;
                padding: 10px;
                border: none;
                border-radius: 10px;
                background-color: #3070aa;
                color: white;
                font-size: 20px;
                font-weight: 500;
                cursor: pointer;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .loginButton:disabled{
                cursor: not-allowed;
                background-color: #306faa7a;
              }
              

        }
    }
}

