
.chart {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    color: gray;

    .top {
        display: flex;
        // justify-content: space-between;
        // align-items: center;

        .title {
            display: flex;
            color: lightgray;
            white-space: nowrap;
        }

        .inputs {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-self: end;
            align-items: end;
            margin-right: 10px;
        }
    }

    .chartGrid {
        stroke: rgb(228, 225, 225);
    }

}
