.table {
  height: auto;
  background-color: grey;

  a {
    text-decoration: none;
  }

  .tableHeader {
    font-weight: bold;
  }

  .cellWrapper {
    display: flex;
    align-items: center;

    .image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }
  }

  .cellWithStatus {
    text-transform: capitalize;

    padding: 5px;
    border-radius: 5px;

    &.online {
      color: green;
      background-color: rgba(0, 128, 0, 0.151);
    }

    &.offline {
      color: goldenrod;
      background-color: rgba(189, 189, 3, 0.103);
    }
  }
}
