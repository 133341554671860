.meter {
    display: flex;
    width: 100%;

    .meterContainer {
        flex: 6;

        h2 {
            font-size: 20px;
            margin-left: 20px;
            margin-top: 10px;
        }

        .title {
            font-size: 16px;
            color: lightgray;
            margin-bottom: 20px;
        }

        .top {
            padding: 10px 20px;
            display: flex;
            gap: 20px;

            .left {
                flex: 1;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 10px;
                position: relative;

                .editButton {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 5px;
                    font-size: 12px;
                    color: #7451f8;
                    background-color: #7551f818;
                    cursor: pointer;
                    border-radius: 0px 0px 0px 5px;
                }

                .item {
                    display: flex;
                    gap: 20px;

                    .itemImg {
                        flex: 1;
                        min-width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        object-fit: cover;
                    }

                    .details {
                        flex: 50;
                        .itemTitle {
                            // margin-bottom: 10px;
                            color: #555;
                        }

                        .itemDetail {
                            display: flex;
                            // width: 250px;
                            
                            margin-bottom: 10px;
                            font-size: 14px;

                            .itemKey {
                                // min-width: 60px;
                                flex: 2;
                                font-weight: bold;
                                color: gray;
                                white-space: nowrap;
                                // padding-right: 5px;
                                // margin-right: 5px;
                            }

                            .itemValue {
                                flex: 3;
                                white-space: nowrap;
                                min-width: 40px;
                            }
                        }
                    }
                }
            }

            .right {
                flex: 3;
            }
        }

        .bottom {
            // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            // padding: 20px;
            margin: 10px 20px;
        }

    }

}

