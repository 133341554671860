.list {
    display: flex;
    width: 100%;

    .listContainer {
        flex: 6
    }
    

}

