.meterList {
    // width: 100%;

    .meterListWrapper{
        display: flex;
        flex-direction: column;

        h2 {
            font-size: 26px;
            padding-bottom: 20px;
        }
    
        .locationTable {
            width: 800px;

            // border: 1px solid red;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 10px 20px;
        }

        .location {
            // display: flex;

            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 10px;
            // color: gray;
    
            h2 {
                font-size: 20px;
                color: grey;
                margin-bottom: 20px;
            }
        }
    }
}