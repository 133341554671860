.custom-tooltip {
    background-color: #ffffff;
    border: 1px solid rgb(230, 227, 277);
    border-radius: 10px;
    padding: 10px;
    color: red;
    .label {

    }
    .energy {
        color: green;
    }
    .compared {
        color: orange;
    }
}

.chart {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    color: gray;

    .top {
        display: flex;
        // justify-content: space-between;
        // align-items: center;

        .title {
            display: flex;
            font-size: 16px;
            color: lightgray;
            white-space: nowrap;
        }

        .inputs {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-self: end;
            // align-items: center;
            margin-right: 10px;

            .firstRow {
                display: flex;
                align-self: end;
                align-items: center;
                margin-bottom: 5px;
                .interval {
                    display: flex;
                    justify-content: space-between;
                    width: 280px;
                    height: 30px;
                    margin-right: 40px;
                }
                .dateRange {
                    display: flex;
                    
                }
            }

            .secondRow {
                display: flex;
                align-self: end;
                align-items: center;
                .compareLabel {
                    margin-right: 20px;
                    font-weight: 600;
                }
                .meterDropdown {
                    margin-right: 40px;
                }
            }

        }
    }

    .chartGrid {
        stroke: rgb(228, 225, 225);
    }

}
