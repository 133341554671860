.navbarContainer {
    z-index: 100;
    height: 60px;
    width: 100%;
    padding: 20px;
    display: flex;
    border-bottom: 0.5px solid rgb(230, 227, 277);
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    font-size: 14px;
    background-color: #3070aa;


    // justify-content: space-between;

    .navbarLinks {
        display: flex;

        .linkItem {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;

            .icon {
                font-size: 20px;
            }
    
            .link {
                color: white;
                font-size: 16px;
                cursor: pointer;
                text-decoration: none;
            }
        }
    }

    .navbarLeft {
        flex: 1;
        display: flex;
        // padding-right: max(15px, 1%);
        padding-right: 15px;
        .logo {
            font-size: 24px;
            font-weight: bold;
            color: white;
        }
    }

    .navbarCenter {
        flex: 8;
        display: flex;
        // background-color: blue;

    }

    .navbarRight {
        flex: 1;
        display: flex;
        // background-color: red;
        // justify-content: space-between;
        padding-right: 15px;

        .avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
        
        .icons {
            display: flex;
            align-items: center;
            margin-right: 20px;
            position: relative;

            .counter {
                width: 15px;
                height: 15px;
                background-color: red;
                border-radius: 50%;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                font-weight: bold;
                position: absolute;
                top: -5px;
                right: -5px;
            }

        }        


    }

        // .search {
        //     display: flex;
        //     align-items: center;
        //     border: 0.5px solid lightgray;
        //     padding: 3px;
        //     input {
        //         border: none;
        //         outline: none;
        //         background: transparent;
        //         &::placeholder {
        //             font-size: 12px;
        //         }
        //     }
        // }

        // .items {
        //     display: flex; 
        //     align-items: center;
        //     .item {
        //         display: flex;
        //         align-items: center;
        //         margin-right: 20px;
        //         position: relative;

        //         .counter {
        //             width: 15px;
        //             height: 15px;
        //             background-color: red;
        //             border-radius: 50%;
        //             color: white;
        //             display: flex;
        //             align-items: center;
        //             justify-content: center;
        //             font-size: 10px;
        //             font-weight: bold;
        //             position: absolute;
        //             top: -5px;
        //             right: -5px;
        //         }

        //     }
        // }
    // }
}