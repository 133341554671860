.meter {
    display: flex;
    width: 100%;

    .meterContainer {
        // flex: 6;

        .title {
            font-size: 20px;
            color: lightgray;
            // margin-bottom: 20px;
            padding-bottom: 20px;

        }

        .top {
            // padding: 10px 20px;
            // display: flex;
            // gap: 20px;

            .left  {
                // flex: 3;
            }
            .right {
        }
        }

        .bottom {
            // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            // padding: 20px;
            margin: 10px 20px;
        }

    }

}

